import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { TitleFixed } from '../../../components/PageTemplate'
import {
  Input,
  FormItem,
  Button,
  ButtonGroup,
  Select,
  DatePicker,
  ModalLayout,
  TableMultiSelect,
  TableSearchDropdownProps,
} from '../../../components/Inputs'

import { LoaderProgress, ErrorIcon } from '../../../components/Icons'
import {
  useDictsForSelects,
  useGetChildrenWithServices,
  childrenByStructureAndService,
} from '../../../api/dicts'
import { useProfile } from '../../../api/profile'
import { useGetItem, useSave, useRemove } from '../../../api/visitLists'

import VisitListPosts from './VisitListPosts'

const validationSchema = Yup.object().shape({
  date: Yup.date().required('Обязательное поле!'),
  servicetype_id: Yup.string().required('Обязательное поле!'),
  structure_id: Yup.string().required('Обязательное поле!'),
})

const columns = [
  {
    ...TableSearchDropdownProps('label'),
    title: 'Ребенок',
    dataIndex: 'label',
    key: 'label',
  },
]

const VisitListForm = ({ id, location }) => {
  const posts = useRef()
  const [filteredChildren, setFilteredChildren] = useState([])

  const { currentUser } = useProfile()

  // Data
  const { data, loading, error } = useGetItem(id)
  const [save, { loading: saveLoading }] = useSave()
  const [remove, { loading: removeLoading }] = useRemove(id)

  // Dicts
  const {
    data: { servicesTypes, structure: initStructures },
    dictsLoading,
    dictsError,
  } = useDictsForSelects()

  const structure = useMemo(
    () =>
      Array.isArray(initStructures)
        ? initStructures.filter(
            str =>
              str.level === 2 &&
              Array.isArray(currentUser.structureIds) &&
              currentUser.structureIds.find(userStr => userStr === str.value)
          )
        : [],
    [initStructures, currentUser]
  )

  const { clientChildren, childrenLoading, childrenError } = useGetChildrenWithServices()

  // Prepare
  const visitList = useMemo(() => {
    if (data && id !== 'new') {
      const { user, clientChildren, servicetype, ...visitList } = data

      return {
        ...visitList,
        user: user.name,
        servicetype_id: servicetype.id,
        clientChildrenIds: clientChildren.map(c => c.id),
      }
    }

    // TODO - проверить это. В старых пермишинах все структуры отдавались. Поэтому это не сработало бы
    // если садик для выбора только один - то установим сразу его
    if (id === 'new') {
      return {
        ...data,
        structure_id: structure && structure.length === 1 ? structure[0].value : undefined,
      }
    }

    return {}
  }, [data, id, structure])

  // Prepare dicts
  const parsedServiceTypes = useMemo(
    () =>
      servicesTypes &&
      servicesTypes
        .filter(i => !i.parent_servicetype_id)
        .sort((a, b) => (a.is_parent_servicetype && !b.is_parent_servicetype ? -1 : 1)),
    [servicesTypes]
  )

  // Уствновим список детей для выбора при открытии существующего листа
  useEffect(() => {
    if (visitList && clientChildren.length && id !== 'new') {
      const { servicetype_id, date, structure_id } = visitList
      const filteredChildren = childrenByStructureAndService(
        clientChildren,
        structure_id,
        servicetype_id,
        date
      )
      setFilteredChildren(filteredChildren)
    }
  }, [visitList, clientChildren, id])

  // Обновить список детей и перенести отмеченных со старого списка
  const updateChildrenList = useCallback(
    (values, setFieldValue) => {
      const { structure_id, servicetype_id, date } = values
      if (!servicetype_id || !date || !structure_id) return

      // Дети по услуге на дату
      const filteredClientChildren = childrenByStructureAndService(
        clientChildren,
        structure_id,
        servicetype_id,
        date
      )
      setFilteredChildren(filteredClientChildren)

      // Перенесем выбранных детей с предыдущего списка, если они есть в новом
      const filteredChildrensValue = values.clientChildrenIds
        .map(childValue => {
          const childInFilteredList = filteredClientChildren.find(
            filterChild => filterChild.value === childValue
          )
          return childInFilteredList ? childInFilteredList.value : null
        })
        .filter(i => i)

      setFieldValue('clientChildrenIds', filteredChildrensValue)
    },
    [clientChildren]
  )

  const renderPosts = useCallback(() => <VisitListPosts id={visitList.id} />, [visitList.id])

  if (loading || dictsLoading || childrenLoading) return <LoaderProgress />
  if (error || dictsError || childrenError) return <ErrorIcon />

  return (
    <Formik initialValues={visitList} validationSchema={validationSchema} onSubmit={save}>
      {({ values, setFieldValue }) => {
        return (
          <>
            <ModalLayout
              className='posts-modal'
              ref={posts}
              title='Проводки по документу'
              footer={null}
              render={renderPosts}
            />

            <Form>
              <TitleFixed
                withBackBtn
                text={'Детей: ' + (values ? values.clientChildrenIds.length : 0)}
                commands={
                  <ButtonGroup>
                    <Button loading={removeLoading} icon='delete' type='danger' onClick={remove}>
                      Удалить
                    </Button>
                    <Button
                      icon='schedule'
                      onClick={() => {
                        posts.current.show()
                      }}
                      disabled={visitList.id === 'new'}
                    >
                      Проводки
                    </Button>
                    <Button loading={saveLoading} icon='save' type='primary' htmlType='submit'>
                      Сохранить
                    </Button>
                  </ButtonGroup>
                }
              />
              <FormItem
                label='Дата'
                component={DatePicker}
                name='date'
                allowClear={false}
                onChange={date => updateChildrenList({ ...values, date }, setFieldValue)}
              />
              <FormItem
                label='Услуга'
                component={Select}
                name='servicetype_id'
                placeholder='Выберите тип услуги'
                options={parsedServiceTypes}
                onChange={servicetype_id =>
                  updateChildrenList({ ...values, servicetype_id }, setFieldValue)
                }
              />
              <FormItem
                label='Группа'
                component={Select}
                name='structure_id'
                placeholder='Выберите группу'
                options={structure}
                onChange={(structure_id, { values }) =>
                  updateChildrenList({ ...values, structure_id }, setFieldValue)
                }
              />

              <FormItem
                label='Кто был'
                component={TableMultiSelect}
                name='clientChildrenIds'
                options={filteredChildren}
                columns={columns}
              />

              <FormItem
                disabled={true}
                label='Автор'
                component={Input}
                name='user'
                placeholder='Автор документа'
              />
            </Form>
          </>
        )
      }}
    </Formik>
  )
}

export default VisitListForm
